import React, { lazy, Suspense } from "react";
import "./App.css";
import Footer from "./Pages/Common/Footer";
import Header from "./Pages/Common/Header";
import New from "../src/Pages/NewDesign/index";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./Pages/Common/Loader";

const Home = lazy(() => import("./Pages/Home/Home"));
const Menu = lazy(() => import("./Pages/Menu/Menu"));
const LocationAndHours = lazy(() =>
  import("./Pages/HoursandLocation/LocationAndHours")
);
const Reward = lazy(() => import("./Pages/Reward/Reward"));
const Choose = lazy(() => import("./Pages/Choose/Choose"));
const Career = lazy(() => import("./Pages/Career/Career"));
const FAQ = lazy(() => import("./Pages/FAQ/FAQ"));

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/hours&location" element={<LocationAndHours />} />
            <Route path="/rewardpoints" element={<Reward />} />
            <Route path="/Choose" element={<Choose />} />
            <Route path="/career" element={<Career />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/new" element={<New />} />
          </Routes>
        </Suspense>

        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
