import React, { useState } from "react";
import companylogo from "../../assets/Images/LOGO1BLACKK.svg";
import cart from "../../assets/Images/Icon feather-shopping-bag.png";
import mobilelogo from "../../assets/Images/LOGO2BLACKK.svg";
import cart2 from "../../assets/Images/Icon feather-shopping-bag.svg";
import sidebtn from "../../assets/Images/Group 203.svg";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/Images/LOG.svg";
import shop from "../../assets/Images/Iconshopping.svg";
import SingleProduct from "../../Component/SingleProduct";
import close from "../../assets/Images/Close.svg";
import magnify from "../../assets/Images/Icon open-magnifying-glass.svg";

function Header() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isSingleProductVisible, setIsSingleProductVisible] = useState(false);
  const location = useLocation();
  const isMenuPage = location.pathname === "/menu";
  const [activeLink, setActiveLink] = useState(null);

  const toggleSingleProduct = () => {
    setIsSingleProductVisible(!isSingleProductVisible);
  };

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
    document.body.style.overflow = isSideNavOpen ? "auto" : "hidden";
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    setIsSideNavOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      {isMenuPage ? (
        <header className="desktop_change">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <nav className="navbar navbar-expand-lg mobile_grid">
                  <Link
                    to="/"
                    className={`navbar-brand${
                      activeLink === "Home" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("Home")}
                  >
                    <img className="org_logo" src={logo} alt="company logo" />
                  </Link>

                  <div
                    className=" navbar-collapse space_between "
                    id="navbarNav"
                  >
                    <ul className="navbar-nav links_gap display_none">
                      <li className="nav-item">
                        <Link
                          to="/menu"
                          className={`navbar_links semi-bold link-hover-red ${
                            activeLink === "Menu" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Menu")}
                        >
                          Menu
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/hours&location"
                          className={`navbar_links semi-bold link-hover-red ${
                            activeLink === "Hours & Locations" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Hours & Locations")}
                        >
                          Hours & Locations
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/rewardpoints"
                          className={`navbar_links semi-bold link-hover-red ${
                            activeLink === "Reward POINTS" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Reward POINTS")}
                        >
                          Reward POINTS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/choose"
                          className={`navbar_links semi-bold link-hover-red ${
                            activeLink === "Why forktofitkitchen?"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleLinkClick("Why forktofitkitchen?")
                          }
                        >
                          Why forktofitkitchen?
                        </Link>
                      </li>
                    </ul>
                    <div className="center gap_links_btween">
                      <button onClick={toggleSingleProduct}>
                        <img className="cart2" src={shop} alt="cart_btn" />
                      </button>
                      <h6 className="navbar_links semi-bold">Login</h6>
                      <button className="start_btn display_none">
                        Get Started
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="desktop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <nav className="navbar navbar-expand-lg mobile_grid">
                  <Link
                    to="/"
                    className={`navbar-brand${
                      activeLink === "Home" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("Home")}
                  >
                    <img
                      className="org_logo"
                      src={companylogo}
                      alt="companylogo"
                    />
                  </Link>

                  <div
                    className=" navbar-collapse space_between "
                    id="navbarNav"
                  >
                    <ul className="navbar-nav links_gap display_none">
                      <li className="nav-item">
                        <Link
                          to="/menu"
                          className={`nav-link semi-bold link-hover-red${
                            activeLink === "Menu" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Menu")}
                        >
                          Menu
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/hours&location"
                          className={`nav-link semi-bold link-hover-red ${
                            activeLink === "Hours & Locations" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Hours & Locations")}
                        >
                          Hours & Locations
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/rewardpoints"
                          className={`nav-link semi-bold link-hover-red ${
                            activeLink === "Reward POINTS" ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick("Reward POINTS")}
                        >
                          Reward POINTS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/choose"
                          className={`nav-link semi-bold link-hover-red ${
                            activeLink === "Why forktofitkitchen?"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleLinkClick("Why forktofitkitchen?")
                          }
                        >
                          Why forktofitkitchen?
                        </Link>
                      </li>
                    </ul>
                    <div className="center gap_links_btween">
                      <button onClick={toggleSingleProduct}>
                        <img className="cart1" src={cart} alt="cart_btn" />
                      </button>
                      <a className="nav-link semi-bold" href="#">
                        Login
                      </a>
                      <button className="started_btn display_none">
                        Get Started
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      )}

      {isSingleProductVisible && (
        <SingleProduct
          toggleSingleProduct={toggleSingleProduct}
          isMenuPage={isMenuPage}
        />
      )}
      <header className="mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <nav className="mobile_design">
                <Link
                  to="/"
                  className={`navbar-brand${
                    activeLink === "Home" ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick("Home")}
                >
                  <img
                    className="mobile_logo"
                    src={mobilelogo}
                    alt="companylogo"
                  />
                </Link>

                <div className="">
                  <div className="center gap_links_btween">
                    <button onClick={toggleSingleProduct}>
                      <img className="cart2" src={cart2} alt="cart_btn" />
                    </button>

                    <button className="side_btn" onClick={toggleSideNav}>
                      <img src={sidebtn} alt="" />
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <nav className={`mobile_side_nav ${isSideNavOpen ? "open" : ""}`}>
        <div className="positon-relative">
          <button className={`close_btn`} onClick={toggleSideNav}>
            <img src={close} alt="close" />
          </button>
        </div>
        <button className="meal_btn">
          <img className="magnify_icon" src={magnify} alt="magnify" />
          Search for Meals
        </button>
        <ul>
          <li>
            <Link
              to="/menu"
              className={`navbar_links heavy link-hover-red ${
                activeLink === "Menu" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Menu")}
            >
              Menu
            </Link>
          </li>
          <li>
            <Link
              to="/hours&location"
              className={`navbar_links heavy link-hover-red ${
                activeLink === "Hours & Locations" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Hours & Locations")}
            >
              Hours
            </Link>
          </li>
          <li>
            <Link
              to="/rewardpoints"
              className={`navbar_links heavy link-hover-red ${
                activeLink === "Reward POINTS" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Reward POINTS")}
            >
              Rewards
            </Link>
          </li>
          <li>
            <Link
              to="/choose"
              className={`navbar_links heavy link-hover-red ${
                activeLink === "Why forktofitkitchen?" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Why forktofitkitchen?")}
            >
              Why fork
            </Link>
          </li>
        </ul>
        <button className="meal_btn img_margin">Get Started</button>
        <button className="sign_btn">Sign In</button>
      </nav>

      {isSideNavOpen && (
        <div className="background_overlay" onClick={toggleSideNav}></div>
      )}
    </>
  );
}

export default Header;
