import React, { useState, useEffect } from "react";

function Hero({ heading1, heading2, spanheading }) {
  const [highlightColor, setHighlightColor] = useState("black");

  useEffect(() => {
    const handleURLChange = () => {
      const currentURL = window.location.pathname;
      if (
        currentURL === "/hours&location" ||
        currentURL === "/choose" ||
        currentURL === "/rewardpoints" ||
        currentURL === "/faq" ||
        currentURL === "/career" ||
        currentURL === "/new"
      ) {
        setHighlightColor("#1c1c1c");
      } else {
        setHighlightColor("");
      }
    };

    window.addEventListener("popstate", handleURLChange);
    handleURLChange();
    return () => {
      window.removeEventListener("popstate", handleURLChange);
    };
  }, []);

  return (
    <>
      <h1
        className="menu_landing-heading1 heavy"
        style={{ color: highlightColor }}
      >
        {heading1}
      </h1>
      <h5
        className="menu_landing-heading2 light"
        style={{ color: highlightColor }}
      >
        {heading2}
        <span className="heavy"> {spanheading}</span>
      </h5>
    </>
  );
}

export default Hero;
