import React, { useState } from "react";
import ruler from "../../../../../assets/Images/ruler.svg";
import chicken from "../../../../../assets/Images/Cilantro Lime Chicken.svg";
import chicken1 from "../../../../../assets/Images/BBQ Chicken and Yam Fries.svg";
import chicken2 from "../../../../../assets/Images/Chicken Enchiladas.svg";
import chicken3 from "../../../../../assets/Images/Slim Chicken.svg";
import Meal from "../../../Common/Meal";
const mealBoxes = [
  {
    title: "Cilantro Lime Chicken",
    mealimg: chicken,
    caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
    price: "$5.00",
  },
  {
    title: "BBQ Chicken & Yam Fries",
    mealimg: chicken1,
    caleoriesdetail: "310 cals | 29P, 39C, 2F (grams)",
    price: "$7.50",
  },
  {
    title: "Chicken Enchiladas",
    mealimg: chicken2,
    caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
    price: "$8.00",
  },
  {
    title: "Slim Chicken",
    mealimg: chicken3,
    caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
    price: "$7.50",
  },
];
function Height() {
  const [height, setHeight] = useState("");
  const [showHeight, setShowHeight] = useState(false);
  const [age, setAge] = useState("");

  const handleFindHeightClick = () => {
    setShowHeight(!showHeight);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setHeight(value);
  };
  return (
    <>
      {showHeight ? (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="align_center scroll gap_arrow_btn">
                {mealBoxes.map((meal, index) => (
                  <Meal {...meal} />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="plan-panel">
          <div className="d-flex justify-content-start  flex-column align-items-start">
            <label htmlFor="" className="weight-heading heavy">
              What is your Height ?
            </label>
            <input
              className="weight-input"
              type="text"
              placeholder="Height"
              name="Height"
              value={height}
              onChange={handleInputChange}
            />

            <button
              className={`started_btn semi-bold d-flex mt-5 ${
                !height ? "disabled" : ""
              }`}
              disabled={!height}
              onClick={handleFindHeightClick}
            >
              Generate Plan
            </button>
          </div>
          <div>
            <img className="cake-img" src={ruler} alt="height-icon" />
          </div>
        </div>
      )}
    </>
  );
}

export default Height;
