import React from "react";
import Landing from "./Pages/Home/Landing";
import Menu from "./Pages/Home/Menu/Menu";
import Location from "./Pages/Home/Location/Location";
import Review from "./Pages/Home/Review";
import Contact from "./Pages/Home/Contact";

import Faq from "./Pages/Home/FAQ/FaqDrop";
import Suggestion from "./Pages/Home/Suggestion/Suggestion";
import "./index.css";

function index() {
  return (
    <div>
      <Landing />
      <Menu />
      <Suggestion />
      <Location />
      <Review />
      <Contact />
      <Faq />
    </div>
  );
}

export default index;
