import React, { useState } from "react";
import arrow from "../../../../../assets/Images/downarrow.svg";

function FaqItems({ heading }) {
  const [isRotated, setIsRotated] = useState(false);

  const handleImageClick = () => {
    setIsRotated(!isRotated);
  };

  return (
    <div className="container_list faq_box" onClick={handleImageClick}>
      <h2 className="faq_question heavy semi">{heading}</h2>
      <button
        className={`type_btn align_center transparent ${
          isRotated ? "rotated" : ""
        }`}
      >
        <img className="icon" src={arrow} alt="" />
      </button>
    </div>
  );
}

export default FaqItems;
