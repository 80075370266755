import React from "react";
import increment from "../../../../../assets/Images/increment.svg";
import decrement from "../../../../../assets/Images/Line 13.svg";
import plus from "../../../../../assets/Images/Group 289.svg";
import banger from "../../../../../assets/Images/Label.svg";

function MealBox({ mealimg, caleoriesdetail, title, price }) {
  return (
    <div className="meal-box-container">
      <div className="menu_box position-relative margin_onetwenty center column_direction">
        <img className="meal_category" src={mealimg} alt="meal_categories" />
        <img className="banger" src={banger} alt="banger_icon" />
        <h4 className="category_heading semi-bold">{title}</h4>
        <p className="menu_description regular">
          chicken thigh, cilantro, cilantro lime rice, lime, lime juice, wedge,
          crushed red pepper.
        </p>
        <div className="caleories_box center">
          <p className="menu_caleories">{caleoriesdetail}</p>
        </div>
        <div className="flex_start gap_between">
          <h4 className="price color-red semi-bold">{price}</h4>
          <div className="gap_btn center">
            <div className="add_remove_btn center">
              <button className="count_btn">
                <img src={decrement} alt="decrement" />
              </button>

              <span className="count color-red">1</span>
              <button className="count_btn">
                <img src={increment} alt="decrement" />
              </button>
            </div>

            <button className="addition">
              <img src={plus} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MealBox;
