import React, { useEffect, useState } from "react";
import Hero from "../../../../Common/Hero";
import { Fade } from "react-reveal";
import FaqItems from "./FaqItems";

function FaqDrop() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);
  const initialFaqs = [
    {
      question: "Do you deliver / ship?",
      answer:
        "We're a 1-stop-shop for all your dietary food & beverage needs 50+ meal options are stocked and ready to grab and go.      ",
    },
    {
      question: "How do I track my order?",
      answer: "You can easily track your order by...",
    },
  ];

  const [faqStates, setFaqStates] = useState(initialFaqs.map(() => false));

  const toggleDropdown = (index) => {
    const newFaqStates = [...faqStates];
    newFaqStates[index] = !newFaqStates[index];
    setFaqStates(newFaqStates);
  };

  return (
    <section className="faq_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <Fade bottom>
              <Hero
                heading1="FAQ"
                heading2={
                  isMobile ? "Frequently asked" : "Frequently asked questions."
                }
                spanheading={isMobile ? "questions." : "Find your answers here"}
              />
            </Fade>
            <div className="column_direction reward_gap margin_onetwenty margin_zero">
              {initialFaqs.map((faq, index) => (
                <Fade bottom>
                  <div key={index} onClick={() => toggleDropdown(index)}>
                    <FaqItems heading={faq.question} />
                    {faqStates[index] && (
                      <div className="paragraph-container">
                        <div className="col-lg-8">
                          <p className="faq-paragraph light">{faq.answer}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqDrop;
