import React from "react";

import google from "../assets/Images/Mask Group 8.svg";
import pin from "../assets/Images/Icon material-location-on.svg";
import phone from "../assets/Images/Icon awesome-phone.svg";
import arrow from "../assets/Images/Polygon 2.svg";

function ContactLocation() {
  return (
    <div>
      <div className="flex_start">
        <h5 className="location_street heavy">North Mcallen</h5>
        <div className="align_center scroll_gap flex_direction">
          <button className="red_btn center">
            <img src={arrow} alt="arrow" />
          </button>
          <button className="right-arrow red_btn center">
            <img src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
      <table>
        <tbody className="column_reverse">
          <tr className="flex_direction">
            <th className="address_sub bold">Location</th>
            <td className="address_detail">
              7001 N 10TH ST, SUITE A2, MCALLEN, TX 78504
            </td>
          </tr>
          <tr className="flex_direction">
            <th className="address_sub bold padding_contact">Contact</th>
            <td className="address_detail padding_contact">(956) 627-3284</td>
          </tr>
          <tr className="flex_direction">
            <th className="address_sub bold display_none">Hours</th>
            <td className="address_detail">
              07:30AM - 08:00PM
              <span className="bold"> MONDAY - FRIDAY</span>
            </td>
          </tr>
          <tr className="flex_direction">
            <th className="display_none"></th>
            <td className="address_detail">
              07:30AM - 08:00PM
              <span className="bold"> MONDAY - FRIDAY</span>
            </td>
          </tr>
          <tr className="flex_direction">
            <th></th>
            <th className="address_sub bold none">Hours</th>
            <td className="address_detail">
              07:30AM - 08:00PM
              <span className="bold"> MONDAY - FRIDAY</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="align_center gap_location margin_onetwenty  margin_thirty">
        <img className="google_location" src={google} alt="google_map" />
        <div className="center column_direction  scroll_gap ">
          <button className="pin_btn center">
            <img src={pin} alt="icon" />
          </button>
          <button className="phone_btn">
            <img src={phone} alt="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactLocation;
