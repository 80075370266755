import React, { useRef, useState } from "react";
import location from "../../../../../assets/Images/New Shakeoligist Pic.webp";
import arrow from "../../../../../assets/Images/black_arrow.svg";
import DifferentLocation from "./DiffLocation";
import { Fade } from "react-reveal";

function Locations() {
  const scrollContainerRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const locationData = [
    {
      locationName: "Location 1",
      name: "North McAllen",
      address: "7001 N 10th St a2, McAllen, TX 78504",
      phone: "(956) 627-3284",
    },
    {
      locationName: "Location 2",
      name: "South McAllen",
      address: "1424 E Ridge Rd Ste 4, McAllen, TX 78504",
      phone: "(956) 800-4964",
    },
    {
      locationName: "Location 3",
      name: "Edinburg",
      address: "508 W Trenton Rd Ste C, Edinburg, TX 78539",
      phone: "(956) 513-1649",
    },
    {
      locationName: "Location 4",
      name: "Mission",
      address: "801 N Shary Rd Suite 110, Mission, TX 78572",
      phone: "(956) 766-7327",
    },
    {
      locationName: "Location 5",
      name: "Tres Lagos",
      address: "4200 Tres Lagos Blvd, McAllen, TX 78504",
      phone: "(956) 379-6064",
    },
  ];

  const scrollLeft = () => {
    if (!isScrolling && scrollContainerRef.current && activeSlide > 0) {
      setIsScrolling(true);
      const prevSlide = scrollContainerRef.current.children[activeSlide - 1];
      const scrollDistance = prevSlide.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: -scrollDistance,
        behavior: "smooth",
      });
      setActiveSlide((prev) => Math.max(0, prev - 1));
      setTimeout(() => setIsScrolling(false), 500);
    }
  };

  const scrollRight = () => {
    if (
      !isScrolling &&
      scrollContainerRef.current &&
      activeSlide < locationData.length - 1
    ) {
      setIsScrolling(true);
      const nextSlide = scrollContainerRef.current.children[activeSlide + 1];
      const scrollDistance = nextSlide.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: scrollDistance,
        behavior: "smooth",
      });
      setActiveSlide((prev) => Math.min(locationData.length - 1, prev + 1));
      setTimeout(() => setIsScrolling(false), 500);
    }
  };
  const handleDotClick = (index) => {
    if (
      !isScrolling &&
      scrollContainerRef.current &&
      index >= 0 &&
      index < locationData.length
    ) {
      setIsScrolling(true);
      const targetSlide = scrollContainerRef.current.children[index];
      const targetScrollPosition =
        targetSlide.offsetLeft + targetSlide.clientWidth / 2;
      scrollContainerRef.current.scrollTo({
        left: targetScrollPosition - scrollContainerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
      setActiveSlide(index);
      setTimeout(() => setIsScrolling(false), 500);
    }
  };

  return (
    <Fade bottom>
      <section className="location">
        <div className="container-fluid">
          <div>
            <div className="row">
              <div className="col-lg-5">
                <img className="location_shop" src={location} alt="location" />
              </div>
              <div className="col-lg-7"></div>
            </div>
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="location_auto">
                  <div className="align_center scroll" ref={scrollContainerRef}>
                    {locationData.map((location, index) => (
                      <DifferentLocation
                        name={location.name}
                        address={location.address}
                        phone={location.phone}
                        timing1={location.timing1}
                        timing2={location.timing2}
                        timing3={location.timing3}
                        key={index}
                        visible={activeSlide === index}
                        locationName={location.locationName}
                      />
                    ))}
                  </div>
                  <div className="align_center margin_onefifty gap_arrow_btn ">
                    <div className="align_center scroll_gap flex_direction">
                      <button className="black_btn center" onClick={scrollLeft}>
                        <img src={arrow} alt="arrow" />
                      </button>
                      <button
                        className="right-arrow black_btn center"
                        onClick={scrollRight}
                      >
                        <img src={arrow} alt="arrow" />
                      </button>
                    </div>
                    <div className="center flex_direction gap_dot">
                      <div className="dot_carousel">
                        {locationData.map((_, index) => (
                          <span
                            key={index}
                            className={`dot ${
                              activeSlide === index ? "actives" : ""
                            }`}
                            onClick={() => handleDotClick(index)}
                          ></span>
                        ))}
                      </div>
                      <button className="hr_location_btn center semi-bold">
                        See all hours & locations
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default Locations;
