import React, { useEffect } from "react";

import logo from "../../assets/Images/F2F LOGO - JAN 2022_2@4x-2.svg";

function Loader() {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

export default Loader;
