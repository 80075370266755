import React from "react";
import google from "../../../../../assets/Images/Mask Group 8.svg";
import pin from "../../../../../assets/Images/Icon material-location-on.svg";
import telephone from "../../../../../assets/Images/Icon awesome-phone.svg";

function DiffLocation({ name, address, phone, timing1, timing2, timing3 }) {
  return (
    <div className="col-lg-10">
      <h5 className="location_street heavy">{name}</h5>

      <table>
        <tbody className="column_reverse">
          <tr className="flex_direction">
            <th className="address_sub bold">Location</th>
            <td className="address_detail">{address}</td>
          </tr>
          <tr className="flex_direction">
            <th className="address_sub bold padding_contact">Contact</th>
            <td className="address_detail padding_contact">{phone}</td>
          </tr>
          <tr className="flex_direction">
            <th className="address_sub bold display_none">Hours</th>
            <td className="address_detail">
              7:30AM-8:00PM
              <span className="bold"> MONDAY - FRIDAY</span>
            </td>
          </tr>
          <tr className="flex_direction">
            <th className="display_none"></th>
            <td className="address_detail">
              9:00AM-5:00PM
              <span className="bold"> Saturday</span>
            </td>
          </tr>
          <tr className="flex_direction">
            <th></th>
            <th className="address_sub bold none">Hours</th>
            <td className="address_detail">
              9:00AM-5:00PM
              <span className="bold"> Sunday</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="align_center gap_location margin_onetwenty  margin_thirty">
        <img className="google_location" src={google} alt="google_map" />
        <div className="center column_direction  scroll_gap ">
          <button className="pin_btn center">
            <img src={pin} alt="icon" />
          </button>
          <button className="phone_btn">
            <img src={telephone} alt="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiffLocation;
