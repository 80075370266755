import React, { useState } from "react";
import cake from "../../../../../assets/Images/cake.svg";
import Height from "./Height";

function Age() {
  const [showHeight, setShowHeight] = useState(false);
  const [age, setAge] = useState("");

  const handleFindHeightClick = () => {
    setShowHeight(!showHeight);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setAge(value);
  };

  return (
    <>
      {showHeight ? (
        <Height />
      ) : (
        <div className="plan-panel">
          <div className="d-flex justify-content-start  flex-column align-items-start">
            <label htmlFor="" className="weight-heading heavy">
              What is your Age ?
            </label>
            <input
              className="weight-input"
              type="text"
              placeholder="Age"
              name="Age"
              value={age}
              onChange={handleInputChange}
            />

            <button
              className={`started_btn semi-bold d-flex mt-5 ${
                !age ? "disabled" : ""
              }`}
              onClick={handleFindHeightClick}
              disabled={!age}
            >
              Next
            </button>
          </div>
          <div>
            <img className="cake-img" src={cake} alt="cake-icon" />
          </div>
        </div>
      )}
    </>
  );
}

export default Age;
