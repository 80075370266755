import React, { useState } from "react";
import star from "../../../../assets/Images/Polygon 3.svg";
import left from "../../../../assets/Images/left.svg";
import right from "../../../../assets/Images/right.svg";
import combo1 from "../../../../assets/Images/Chocobomb.webp";
import combo2 from "../../../../assets/Images/Chocobomb2.webp";
import { Fade } from "react-reveal";

const carouselItemsData = [
  {
    clientName: "Fernanado Vlbuena",
    rating: 5,
    review:
      "Great selection of food. Initially thought the portion was small but it filled me up and fueled me up without getting drowsy after eating",
  },
  {
    clientName: "John Vlbuena",
    rating: 4,
    review:
      "Great selection of food. Initially thought the portion was small but it filled me up and fueled me up without getting drowsy after eating",
  },
  {
    clientName: "Alferd Vlbuena",
    rating: 3,
    review:
      "Great selection of food. Initially thought the portion was small but it filled me up and fueled me up without getting drowsy after eating",
  },
];

function Review() {
  const [numItems, setNumItems] = useState(3);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlide = (index) => {
    setActiveIndex(index);
  };

  return (
    <Fade bottom>
      <section className="review">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="position-relative ">
                <img className="combo_1" src={combo1} alt="combobomb" />
              </div>
              <div className="position-relative ">
                <img className="combo_2" src={combo2} alt="combo" />
              </div>
              <h1 className="review_heading heavy">What our customer say</h1>
              <ol className="carousel-dot">
                {Array.from({ length: numItems }, (_, index) => (
                  <li
                    key={index}
                    onClick={() => handleSlide(index)}
                    className={index === activeIndex ? "active" : ""}
                  ></li>
                ))}
              </ol>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner">
                  {carouselItemsData.map((item, index) => (
                    <div
                      key={index}
                      className={`carousel-item${
                        index === activeIndex ? " active" : ""
                      }`}
                    >
                      <div className="center column_direction">
                        <div className="col-lg-10">
                          <h4 className="client_name heavy color-red">
                            {item.clientName}
                          </h4>
                          <div className="center">
                            {Array.from(
                              { length: item.rating },
                              (_, starIndex) => (
                                <img
                                  key={starIndex}
                                  className="rating"
                                  src={star}
                                  alt="star"
                                />
                              )
                            )}
                          </div>
                          <p className="client_review">{item.review}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="carousel-buttons">
                  <button
                    className="carousel-control-prev review_scroll center display_none"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                    onClick={() =>
                      handleSlide((activeIndex - 1 + numItems) % numItems)
                    }
                  >
                    <img src={left} alt="icon" />
                  </button>
                  <button
                    className="carousel-control-next review_scroll center display_none"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                    onClick={() => handleSlide((activeIndex + 1) % numItems)}
                  >
                    <img className="right-arrow" src={right} alt="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default Review;
