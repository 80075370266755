import React from "react";
import ContactLocation from "../../../../Component/ContactLocation";
import close from "../../../../assets/Images/Close.svg";
function Contact() {
  return (
    <section className="">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="carousel-buttons inner_shadow">
              <div className="col-lg-5">
                <div className="padding_box">
                  <h2 className="career_heading heavy">GOT ANY QUESTIONS?</h2>
                  <form action="" className="column_direction reward_gap">
                    <input
                      className="career_input semi-bold"
                      name="full name"
                      type="text"
                      placeholder="Full Name"
                    />
                    <input
                      className="career_input semi-bold"
                      type="email"
                      name="Email"
                      placeholder="Email"
                    />

                    <textarea
                      className="career_input text_height semi-bold"
                      name="message"
                      placeholder="WHAT ARE YOUR QUESTIONS?"
                      cols="30"
                      rows="10"
                    />
                    <button className="started_btn">Send Message</button>
                  </form>
                </div>
              </div>
              <div className="col-lg-7 background-box">
                <div className="padding_box">
                  <ContactLocation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
