import React, { useEffect, useState } from "react";
import increment from "../assets/Images/increment.svg";
import decrement from "../assets/Images/Line 13.svg";
import plus from "../assets/Images/Group 289.svg";
import close from "../assets/Images/Close.svg";

import chilantro from "../assets/Images/Cilantro Lime Chicken.png";

function SingleProduct({ toggleSingleProduct, isMenuPage }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation after component has mounted
    setAnimate(true);
  }, []);
  const containerStyle = {
    backgroundColor: isMenuPage ? "#1c1c1c" : "#ffffff",
    color: isMenuPage ? "#ff5b5b" : "#1c1c1c",
  };

  const cartHeadingStyle = {
    color: isMenuPage ? "#ff5b5b" : "#1c1c1c",
  };

  const cartparagraph = {
    color: isMenuPage ? "#ffffff" : "#1c1c1c",
  };
  const addRomove = {
    backgroundColor: isMenuPage ? "#1c1c1c" : "#ffffff",
    borderColor: isMenuPage ? "#ff5b5b" : "#d3dae6",
  };
  return (
    <section
      className={`single-product ${animate ? "animate" : ""}`}
      style={containerStyle}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="center flex_direction">
              <div className="col-lg-6">
                <img className="cart_img" src={chilantro} alt="item" />
              </div>
              <div className="col-lg-6">
                <h2
                  className={`cart_heading semi-bold`}
                  style={cartHeadingStyle}
                >
                  Cilantro Lime Chicken
                </h2>
                <p className={`cart_paragraph`} style={cartparagraph}>
                  chicken thigh, cilantro, cilantro lime rice, lime, lime juice,
                  wedge, crushed red pepper.
                </p>
                <h6 className="calories">40 Protein</h6>
                <div className="flex_start gap_between flex_direction product_center">
                  <h4 className="price semi-bold color-red">$4.50</h4>
                  <div className="gap_btn center flex_direction">
                    <div className={`add_remove_btn center`} style={addRomove}>
                      <button className={`count_btn`} style={containerStyle}>
                        -
                      </button>

                      <span className={`count`} style={cartparagraph}>
                        1
                      </span>
                      <button className={`count_btn`} style={containerStyle}>
                        +
                      </button>
                    </div>

                    <button className="start_btn">ADD TO BASKET</button>
                  </div>
                </div>
              </div>
              <button
                className={`close_btn`}
                style={containerStyle}
                onClick={toggleSingleProduct}
              >
                <img src={close} alt="close" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleProduct;
