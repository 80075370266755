import React, { useState } from "react";
import multiplestar from "../../../../../assets/Images/multiplestar.svg";
import white_star from "../../../../../assets/Images/white_star.svg";
import Weight from "./Weight"; // Import the new component

function Suggestion() {
  const [showPlanPanel, setShowPlanPanel] = useState(false);

  const handleFindPlanClick = () => {
    setShowPlanPanel(!showPlanPanel);
  };

  return (
    <section className="faq_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 d-flex justify-content-center  flex-column align-items-center">
            <h1 className="hero_heading heavy text-center">
              Food Suggestion{" "}
              <span className="color-red d-block">
                With AI{" "}
                <img
                  className="multiple-star"
                  src={multiplestar}
                  alt="multiple-star"
                />
              </span>
            </h1>
            <p className="hero_paragraph text-center">
              We are the better Alternative to Fast Food. We bring fresh{" "}
              <span className="semi-bold">high-quality ingredients </span>
              together <br /> and create homemade foods, perfectly portioned for
              you.
            </p>

            {showPlanPanel ? (
              <Weight /> // Render the new panel when showPlanPanel is true
            ) : (
              <button
                className="started_btn semi-bold d-flex mt-5"
                onClick={handleFindPlanClick}
              >
                Find my plan{" "}
                <img className="white_star" src={white_star} alt="star_icon" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Suggestion;
