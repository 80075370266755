import React from "react";
import hero from "../../../../assets/Images/hero-banner.webp";
import banner_mobile from "../../../../assets/Images/Scroll Group 11.webp";
import { Fade } from "react-reveal";

function Landing() {
  const handleDownloadPDF = () => {
    const pdfFilePath = process.env.PUBLIC_URL + "/menu.pdf";
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.setAttribute("download", "menu.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Fade bottom>
      <section className="hero_banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="center">
                <div className="col-lg-6">
                  <h1 className="hero_heading heavy">
                    Portioned Meals
                    <span className="color-red">Ready to go</span> for You!
                  </h1>
                  <img className="banner_mobile" src={banner_mobile} alt="" />
                  <p className="hero_paragraph">
                    100% fresh, chef cooked
                    <span className="semi-bold"> macro-friendly</span> meals and
                    drinks ready to pick-up at your local Forktofitkitchen
                  </p>
                  <div className="flex_start btn-gap flex_direction">
                    <button className="started_btn semi-bold">
                      Get Started
                    </button>
                    <button
                      className="explore_btn semi-bold"
                      onClick={handleDownloadPDF}
                    >
                      Explore Menu
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img className="banner-image" src={hero} alt="banner_image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default Landing;
