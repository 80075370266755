import React, { useRef } from "react";
import { Fade } from "react-reveal";
import MealBox from "./MealBox";
import chicken from "../../../../../assets/Images/Cilantro Lime Chicken.svg";
import chicken1 from "../../../../../assets/Images/BBQ Chicken and Yam Fries.svg";
import chicken2 from "../../../../../assets/Images/Chicken Enchiladas.svg";
import chicken3 from "../../../../../assets/Images/Slim Chicken.svg";
import right from "../../../../../assets/Images/right.svg";
import left from "../../../../../assets/Images/left.svg";
import { useState } from "react";
import { useEffect } from "react";

function Menu() {
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollDistance = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: direction === "left" ? -scrollDistance : scrollDistance,
        behavior: "smooth",
      });
    }
  };

  const mealBoxes = [
    {
      title: "Cilantro Lime Chicken",
      mealimg: chicken,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$5.00",
    },
    {
      title: "BBQ Chicken & Yam Fries",
      mealimg: chicken1,
      caleoriesdetail: "310 cals | 29P, 39C, 2F (grams)",
      price: "$7.50",
    },
    {
      title: "Chicken Enchiladas",
      mealimg: chicken2,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$8.00",
    },
    {
      title: "Slim Chicken",
      mealimg: chicken3,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$7.50",
    },
    {
      title: "Cilantro Lime Chicken",
      mealimg: chicken,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$5.00",
    },
    {
      title: "BBQ Chicken & Yam Fries",
      mealimg: chicken1,
      caleoriesdetail: "310 cals | 29P, 39C, 2F (grams)",
      price: "$7.50",
    },
    {
      title: "Chicken Enchiladas",
      mealimg: chicken2,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$8.00",
    },
    {
      title: "Slim Chicken",
      mealimg: chicken3,
      caleoriesdetail: "490 cals | 40P, 49C, 28F (grams)",
      price: "$7.50",
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(scrollContainerRef.current.scrollLeft);
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Fade bottom>
      <section className="menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="position-relative">
                <h1 className="menu_heading heavy">Our delicious menu</h1>
                <h6 className="menu_subheading">mmm.. so Yummy!</h6>
                <h4 className="menu_subheading2 color-red heavy">
                  customer favorites
                </h4>
              </div>

              <div
                className="align_center scroll gap_arrow_btn"
                ref={scrollContainerRef}
              >
                {mealBoxes.map((meal, index) => (
                  <MealBox {...meal} />
                ))}
              </div>

              <div className="flex_start scroll_gap margin_scroll">
                <button
                  className="scroll_btn center"
                  onClick={() => scroll("left")}
                >
                  <img src={left} alt="arrow" />
                </button>
                <button
                  className="scroll_btn center"
                  onClick={() => scroll("right")}
                >
                  <img className="right-arrow" src={right} alt="" />
                </button>
                <button className="see_btn semi-bold">SEE ENTIRE MENU</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default Menu;
