import React, { useEffect, useState } from "react";
import logo from "../../assets/Images/F2F LOGO - JAN 2022_2@4x-2.svg";
import apple from "../../assets/Images/Icon awesome-apple.svg";
import google from "../../assets/Images/Icon awesome-google-play.svg";
import instagram from "../../assets/Images/Icon feather-instagram.svg";
import facebook from "../../assets/Images/Icon feather-facebook.svg";
import staff from "../../assets/Images/Gr.webp";
import Contact from "../Contact/Contact";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import tiktok from "../../assets/Images/tiktok.svg";
import youtube from "../../assets/Images/youtube.svg";
import linkedin from "../../assets/Images/Linkedin.svg";
import twitter from "../../assets/Images/Twitter.svg";

function Footer() {
  const [showContact, setShowContact] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="col-lg-6">
              <img className="company_logo" src={logo} alt="company_logo" />
              <div className="flex_start gap_footer footer_margin flex_direction z-index">
                <div className="flex_start column_direction">
                  <h4 className="footer_heading heavy">Forks up</h4>
                  <Link
                    to="/menu"
                    className="links_anchor"
                    onClick={scrollToTop}
                  >
                    Menu
                  </Link>
                  <Link
                    to="/hours&location"
                    className="links_anchor"
                    onClick={scrollToTop}
                  >
                    Hours & Locations
                  </Link>
                  <Link
                    to="choose"
                    className="links_anchor no_margin"
                    onClick={scrollToTop}
                  >
                    Why FORKTOFITKITCHEN?
                  </Link>
                  <h5 className="downapp_heading heavy margin_seventy">
                    Download our App
                  </h5>
                  <div className="flex_start gap_between_btn ">
                    <button className="btn_download center">
                      <img src={apple} alt="logo" />
                      Apple
                    </button>
                    <button className="btn_download center">
                      <img src={google} alt="logo" />
                      Google
                    </button>
                  </div>
                </div>
                <div className="flex_start column_direction footer_padding">
                  <h4 className="footer_heading heavy">ABOUT</h4>
                  <Link
                    to="/faq"
                    className="links_anchor"
                    onClick={scrollToTop}
                  >
                    FAQ
                  </Link>
                  <Link
                    to="/career"
                    className="links_anchor"
                    onClick={scrollToTop}
                  >
                    Career
                  </Link>

                  <button
                    className="contact_btn semi-bold margin_fourtytwo display_none"
                    onClick={() => setShowContact(!showContact)}
                  >
                    Contact
                  </button>
                  <h5 className="downapp_heading heavy ">WHAT'S UP?</h5>

                  <div className="flex_start gap_between">
                    <a
                      href="https://www.instagram.com/forktofitkitchen/?igshid=MzRlODBiNWFlZA%3D%3D"
                      target="__blank"
                    >
                      <img src={instagram} alt="social_icon" />
                    </a>
                    <a
                      href="https://www.facebook.com/forktofitkitchen?mibextid=2JQ9oc"
                      target="__blank"
                    >
                      <img src={facebook} alt="social_icon" />
                    </a>
                    <a
                      href="https://www.tiktok.com/@forktofitkitchen?_t=8f8xK3GXoLr&_r=1"
                      target="__blank"
                    >
                      <img src={tiktok} alt="social_icon" />
                    </a>
                    <a
                      href="https://www.youtube.com/@forktofitkitchen2320"
                      target="__blank"
                    >
                      <img src={youtube} alt="social_icon" />
                    </a>
                  </div>
                  <div className="flex_start gap_between checkbox-list ">
                    <a
                      href="https://www.linkedin.com/company/fork-to-fit-kitchen/"
                      target="__blank"
                    >
                      <img src={linkedin} alt="social_icon" />
                    </a>
                    <a
                      href="https://x.com/myforktofit?s=21&t=7T-SZiir1TdQ7JRghqn7dQ"
                      target="__blank"
                    >
                      <img src={twitter} alt="social_icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex_start scroll_gap footer_margin display_none">
                <h6 className="footer_content ">
                  © 2022 FORKTOFITKITCHEN, LLC.
                </h6>
                <div className="center">
                  <h6 className="footer_content ">| PRIVACY</h6>
                  <h6 className="footer_content ">| TERMS </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer_absolute">
          <div className="col-lg-5"></div>
          <div className="col-lg-7">
            <img src={staff} alt="staff" />
          </div>
        </div>
      </div>
      <div className="center copywrite_bg">
        <h6 className="footer_content ">© 2022 FORKTOFITKITCHEN, LLC.</h6>
        <div className="center">
          <h6 className="footer_content ">| PRIVACY</h6>
          <h6 className="footer_content ">| TERMS </h6>
        </div>
      </div>
      {showContact && (
        <div className="contact-component">
          <Contact setShowContact={setShowContact} />
        </div>
      )}
    </footer>
  );
}

export default Footer;
